<script>
import moment from 'moment-timezone'
export default {
  components: {  
    
  },
  props: {
  
  },
  created(){
   
  },
  data() {
     return {
       recording: false,
       audioBlob: null,
       startDateTime: null,
       endDateTime: null
    };
   
  },
  computed:{
    duration() {
      if(!this.startDateTime || !this.endDateTime) return "";
      const diff = this.endDateTime.diff(this.startDateTime);
      return moment.utc(diff).format("mm:ss");
    }
  },
  watch: {
    
  },
  methods:{
    tick(){
      this.endDateTime = moment();
    },
    start(){
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const mediaRecorder = new window.MediaRecorder(stream, { mimeType: 'audio/wav' }, window.workerOptions);
          mediaRecorder.start();
          this.startDateTime = moment();
          this.endDateTime = moment();
          this.timer = setInterval(this.tick, 1000);
          this.mediaRecorder = mediaRecorder;
          //const audioChunks = [];
          this.recording = true;
          this.$emit('start', this.audioBlob);
          mediaRecorder.addEventListener("dataavailable", event => {
           // console.log('dataavailable', event);
            this.audioBlob = event.data;
            //audioChunks.push(event.data);
          });
          mediaRecorder.addEventListener("stop", () => {
           
            //this.audioBlob = new Blob(audioChunks);
             //console.log('stop', this.audioBlob);
             mediaRecorder.stream.getTracks().forEach(i => i.stop());
          });
        });
    },
    stop() {
      if(this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.mediaRecorder = null;
      }
      if(this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.startDateTime = null;
      this.endDateTime = null;
      this.recording = false;
   },
   cancel() {
     this.audioBlob = null;
      this.$emit('stop');
   },
   send() {
      this.$emit('ready', {
       fileBlob: this.audioBlob,
       fileName: 'Voice_Record',
       mimeType: 'audio/wav'
      });
     this.audioBlob = null;
      this.$emit('stop');
   }
  }
};
</script>

<template>
  <span>
    <span  v-if="!recording && !audioBlob" class="font-size-24  p-0 text-primary" style="cursor: pointer;" @click="start">
      <i class="fas fa-microphone-alt"/>
    </span>
    <span v-if="recording" class="font-size-24  p-0 text-primary  text-right" style="cursor: pointer;" @click="stop">
      <small class="me-1  text-muted" style="width: 70px; display: inline-block; ">
        {{duration}}
      </small>
      <i  class="fas fa-stop-circle"/>
    </span>
   
    <span  v-if="!recording && audioBlob" class="font-size-24  p-0 text-muted me-2" style="cursor: pointer;" @click="cancel">
      <i class="fas fa-trash-alt"/>
    </span>
     <span  v-if="!recording && audioBlob" class="font-size-24  p-0 text-success" style="cursor: pointer;" @click="send">
      <i class="fas fa-check-circle"/>
    </span>
  </span>
</template>
