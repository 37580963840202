<script>
import router from "@/router";
import { mapGetters, mapActions } from 'vuex';
import SideBar from "@/components/side-bar";
import { layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";
/**
 * Vertical component
 */
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    isBusy: {
      
      required: false
    }
  },
  data() {
    return {
      rightBarConfig: {
        handler: this.hideRightSidebar,
        middleware: this.middleware,
        events: ["click"],
      },
    };
  },
  components: {  SideBar, SimpleBar },
  created: () => {
    document.body.removeAttribute("data-layout");
  },
  computed: {
    
    ...mapGetters('boot', {
      loading: 'loading'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      subscription: 'subscription'
    }),
  
    ...layoutComputed,
  },
  methods: {
    ...mapActions('auth', {
      logOut: 'logOut'
    }),
   
    tryLogOut(){
       this.logOut();
       this.$router.push( { name: 'login'} );
    },
    onContactFormClose() {
      this.cancelContactEdit();
    },
    onRoutechange() {
      setTimeout(() => {
        if(document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if(this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      }, 300);
    },
    toggleMenu() {
      var currentSIdebarSize = document.body.getAttribute("data-sidebar-size");
      document.body.classList.toggle("sidebar-enable");
      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        if (currentSIdebarSize == null) {
          document.body.getAttribute("data-sidebar-size") == null ||
          document.body.getAttribute("data-sidebar-size") == "lg"
            ? document.body.setAttribute("data-sidebar-size", "sm")
            : document.body.setAttribute("data-sidebar-size", "lg");
        } else if (currentSIdebarSize == "md") {
          document.body.getAttribute("data-sidebar-size") == "md"
            ? document.body.setAttribute("data-sidebar-size", "sm")
            : document.body.setAttribute("data-sidebar-size", "md");
        } else {
          document.body.getAttribute("data-sidebar-size") == "sm"
            ? document.body.setAttribute("data-sidebar-size", "lg")
            : document.body.setAttribute("data-sidebar-size", "sm");
        }
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
     
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
     
      document.body.classList.remove("right-bar-enabled");
    },
    middleware(event) {
      if(!event.target || !event.target.classList)
        return false;
      return !event.target.classList.contains("toggle-right");
    },
  },
};
</script>

<template>
  <div>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <header id="page-topbar" class="isvertical-topbar" style="border:0">
        <div class="navbar-header">
          <div class="d-flex" style="min-width:20px">
            <button
              type="button"
              class="
                btn btn-sm
                px-3
                font-size-16
                header-item
                vertical-menu-btn
                topnav-hamburger
              "
              @click="toggleMenu"
            >
              <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

            <div class=" align-self-center">
              <h4 class="page-title">
                <span class=" d-none d-sm-block ms-3 ">{{ title }} <i class=" ms-2 fa fa-spin fa-spinner" v-if="isBusy || loading"/></span>
                <i class=" ms-2  d-block d-sm-none fa fa-spin fa-spinner" v-if="isBusy || loading"/>
              </h4>
            </div>
           
          </div>
         
          <div class="d-flex d-flex top-toolbar table-responsive" >
            <slot name="toptoolbar" :toggleRightSidebar="toggleRightSidebar" :hideRightSidebar="hideRightSidebar">

            </slot>
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn header-item user text-start d-flex align-items-center"
                id="page-header-user-dropdown-v"
                data-bs-toggle="dropdown"
                data-bs-reference="parent"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="mdi-face mdi font-size-24"></i>
                <i class="mdi mdi-chevron-down font-size-16"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end pt-0">
                <div class="p-3 border-bottom">
                  <h6 class="mb-0">{{(currentUser || {}).name}}</h6>
                  <p class="mb-0 font-size-11 text-muted">
                    {{((currentUser || {}).account || {}).name}}
                  </p>
                </div>
                <router-link class="dropdown-item" to="/profile"
                  >
                  <i
                    class="
                      mdi mdi-account-circle
                      text-muted
                      font-size-16
                      align-middle
                      me-1
                    "
                  ></i>
                  <span class="align-middle">Profile</span></router-link
                >
                <!-- <a class="dropdown-item d-flex align-items-center" href="#"
                  ><i
                    class="
                      mdi mdi-cog-outline
                      text-muted
                      font-size-16
                      align-middle
                      me-1
                    "
                  ></i>
                  <span class="align-middle">Settings</span
                  >
                </a> -->
                <a class="dropdown-item" href="javascript:void(0)" @click="tryLogOut"
                  ><i
                    class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
                  ></i>
                  <span class="align-middle">Logout</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <edit-form v-if="contactEditItemId" @close="onContactFormClose" :id="contactEditItemId">
      </edit-form>

      <SideBar
      
        :type="leftSidebarType"
        :width="layoutWidth"
        :color="sidebarColor"
      />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content" style="position: relative;">
        <div class="page-content" style="padding: 0; padding-top:80px;">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
    <div>
    <!-- Right Sidebar -->
    <div class="right-bar" v-click-outside="rightBarConfig">
      <SimpleBar class="h-100">
        <slot name="rightbar" :toggleRightSidebar="toggleRightSidebar" :hideRightSidebar="hideRightSidebar"></slot>
      </SimpleBar>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>

    <!-- chat offcanvas -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasActivity"
      aria-labelledby="offcanvasActivityLabel"
    >
      <div class="offcanvas-header border-bottom">
        <h5 id="offcanvasActivityLabel"> </h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">...</div>
      </div>
    </div>
  </div>
</template>
