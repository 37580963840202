<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { parseErrors} from "@/helpers"
import { popModal, pushModal } from "@/modals";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  
  props: {
    id:{
       type: String,
       required: true
    }
  },
  data() {
    return {
      errors: [],
      item: {
        botName: '',
        token: '',
        greetingsText: '',
        askContactPhone: false,
        disabled: false
      }
    };
  },
  validations() {
    const rules = {
      item: {
        botName: { required },
        token: { required }
      }
    };

    return rules;
  },
  created(){
    this.title = this.isNew ? "New Telegram Bot" : "Edit Telegram Bot";
    this.init();
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'static', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    isBusy() {
      return this.loading;
    },
    isNew () {
      return this.id.toLowerCase().trim() === 'new';
    },
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('bots', {
      loading: 'loading',
      processing: 'processing'
    })
  },
  methods: {
    ...mapActions('bots', {
      loadTelegramBot: 'loadTelegramBot',
      createTelegramBot: 'createTelegramBot',
      updateTelegramBot: 'updateTelegramBot',
    }),
    async close(res) {
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close", res);
    },
    async init() {
     
      if(!this.isNew) {
        const dto = JSON.parse(JSON.stringify(await this.loadTelegramBot( { id: this.id })));
        const {
          token
        } = dto;

        const {
          botName,
          greetingsText,
          askContactPhone,
          disabled
        } = dto.basicInfo;

        this.item = { 
          botName,
          token,
          greetingsText,
          askContactPhone,
          disabled
         };

        
      }
    },
   
    async submit () {
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.errors = [];
        
        const {
        
          botName,
          token,
          greetingsText,
          askContactPhone,
          disabled
        } = this.item;
        
        const dto = {
         
          botName,
          token,
          greetingsText,
          askContactPhone,
          disabled
        };
        let req = this.isNew ? this.createTelegramBot({ dto }) : this.updateTelegramBot({ id: this.id, dto: dto});
        req.then(() => {
          this.$toast.open({message: 'Bot was saved successfully.'});
          this.close(true);
        })
        .catch(error => {
          this.errors = parseErrors(error);
        });
      }
    }
  }
};
</script>

<template>
  <div
    class="modal modal-md"
    ref="modal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title me-4">
            {{title}}<i class="fa fa-spin fa-spinner ms-2" v-if="isBusy"/>
          </h5>
         
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>

            <div class="mb-4">
              <label for="botName-input" class="col-form-label">Bot Name</label>
              <div >
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Enter Bot Name" 
                  id="botName-input" 
                  v-model="item.botName"
                  :class="{ 'is-invalid': v$.item.botName.$error }"
                />
                <div class="invalid-feedback">
                  Please Enter Bot Name
                </div> 
              </div>
            </div>
            <div class="mb-4">
              <label for="token-input" class="col-form-label">Bot Access Token</label>
              <div >
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Enter Bot Access Token" 
                  id="token-input" 
                  v-model="item.token"
                  :class="{ 'is-invalid': v$.item.token.$error }"
                />
                <div class="invalid-feedback">
                  Please Enter Bot Access Token
                </div> 
              </div>
            </div>
            <div class="mb-4">
              <label for="greetingsText-input" class="col-form-label">Greetings Text</label>
              <div >
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Enter Greetings Text" 
                  id="greetingsText-input" 
                  v-model="item.greetingsText"
                />
              </div>
            </div>

            <div class=" mb-4">
              <label for="askContactPhone-input" class="col-form-label"></label>
              <div class="">
                <div class="form-check form-switch form-switch-md mb-3">
                  <input class="form-check-input" type="checkbox" id="askContactPhone-input" v-model="item.askContactPhone"/>
                  <label class="form-check-label" for="askContactPhone-input">Ask Contact Phone</label>
                </div>
              </div>
            </div>

            <div class=" mb-4">
              <label for="disabled-input" class="col-form-label"></label>
              <div class="">
                <div class="form-check form-switch form-switch-md mb-3">
                  <input class="form-check-input" type="checkbox" id="disabled-input" v-model="item.disabled"/>
                  <label class="form-check-label" for="disabled-input">Disabled</label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            @click="close"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary"
            :disabled="processing || isBusy" 
            @click="submit"
          >
           <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
           <i class="fa fa-save me-2" v-if="!processing"/>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
