<script>
import draggable from 'vuedraggable'

export default {
  components: {  
    draggable
  },
  props: {
    noLink:{
      type: Boolean,
      default: () => false
    },
    value: {
      type: Array,
      required: false,
      default: ()=> []
    },
    editable:{
      type: Boolean,
      default: () => false
    },
    copy: {
      type: Boolean
    }
  },
  data() {
    return {
      phone: '',
      isAdding: false
    };
  },
  computed:{
    array:
    {
      get: function() {
        let a = [];
        this.value.forEach(x => a.push({id: x, value: x}));
        
        return a;
      },
      set: function(newValue) {
         this.$emit("update", newValue.map(x => x.value));
      }
    },
  },
  methods:{
    add(){
      this.phone = null;
      this.isAdding = true;
      this.$nextTick(()=> this.$refs.input.focus());
    },
    end() {
      if(this.phone) {
        this.$emit("update", [...new Set([...this.value, this.phone])]);
      }
      this.phone = null;
      this.isAdding = false;
    },
    remove(phone){
      this.$emit("update", [...new Set(this.value.filter(x => x !== phone))]);
    },
    checkSymbol ($event) {
     
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 43) { 
          $event.preventDefault();
        }
    },
  }
};
</script>

<template>
  <span>
   
    <span v-if="!editable"> 
      <span class="me-3 mb-1" 
        v-for="(t, $index) in value" :key="$index"
      >
        <a v-if="!noLink" :href="'tel:' + t">
          {{t}}
        </a>
        <span v-if="noLink">
          {{t}}
        </span>
        <a v-clipboard:copy="t" href="javascript:void(0)" v-if="copy" class="text-muted ms-1 small" title="Copy">
          <i class="mdi-content-copy mdi"/>
        </a>
      </span>
    </span>
    <span v-if="editable">
      <draggable 
        tag="span"
        v-model="array" 
        group="phones" 
        @start="drag=true" 
        @end="drag=false" 
        item-key="index"
      >
        <template #item="{element}">
          <span class="me-2 form-control  me-2 mb-1" 
            style="display:inline-block; width:auto" 
          
          >
          
            {{element.value}}
            <small class="ms-2"  v-if="editable">
              <a href="javascript:void(0)" @click="remove(element.value)">&#10006;</a>
            </small>

          </span>
        </template>
      </draggable>

      <!-- <span class="me-2 form-control  me-2" 
        style="display:inline-block; width:auto" 
        
        v-for="(t, $index) in value" :key="$index"
      >
        <i class="fas fa-phone me-1"/>
        {{t}}
        <small class="ms-2"  v-if="editable">
          <a href="javascript:void(0)" @click="remove(t)">&#10006;</a>
        </small>

      </span> -->
    </span>
    
    <a href="javascript:void(0)" 
      class="btn btn-outline-light waves-effect "
      v-if="!isAdding && editable"
      @click="add"
    >
      <i class="fas fa-plus me-1"></i> Add Phone
    </a>
    <div class="form-control" style="display:inline-block; min-width:150px;width:150px;position:relative; padding:0; z-index:10"
      v-click-outside="end"  
      v-if="isAdding"
     >
      <input 
        @keypress="checkSymbol"
        class="form-control"
        style="border:0;"
        v-model="phone" 
        ref="input"
        v-on:keyup.enter="end"
      />
    </div>
   
  </span>
</template>
