<script>
import { mapGetters } from 'vuex';

export default {
  name: "message",
  props: {
    conversation: {
      type: Object,
      required: true
    },
    msg: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      play: false
    };
  },
  computed: {
    ...mapGetters('auth', {
      token: 'token'
    }),
    fileUrl() {
      return `${process.env.VUE_APP_URL}conversations/${this.conversation.platform}/${this.conversation.id}/messages/${this.msg.id}/files/${this.msg.fileId}?token=${this.token}`;
    }
  },
  methods: {
    
  }
};
</script>

<template>
  <div>
    <div class="ms-2 mb-2 p-2" style="border-left: 2px solid #57c9eb"  v-if="msg.replyTo">
      <message :msg="msg.replyTo" :conversation="conversation"></message>
    </div>
     {{msg.text}}
    
    <div v-if="msg.messageType === 'Voice' || msg.messageType === 'Audio'" :class="{'p-0': play}"> 
      <div v-if="!play">{{msg.caption}}</div>
    
      <a href="javascript:void(0)" class="btn btn-info btn-rounded waves-effect waves-light btn-sm mt-1" v-if="!play" @click="play = true">
        <i class="mdi mdi-play-outline "/>
        {{msg.fileName || 'Audio'}}
      </a>
      <audio autoplay controls v-if="play">
        <source :src="fileUrl">
      </audio>
    </div>
    <div v-if="msg.messageType === 'Video'"  :class="{'p-0': play}"> 
      <div v-if="!play">{{msg.caption}}</div>
     
      <a href="javascript:void(0)" class="btn btn-info btn-rounded waves-effect waves-light btn-sm mt-1" v-if="!play" @click="play = true">
        <i class="mdi mdi-play-outline "/>
        {{msg.fileName || 'Video'}}
      </a>
      <video autoplay controls v-if="play">
        <source :src="fileUrl">
      </video>
    </div>
    <div v-if="msg.messageType === 'Photo'" > 
      <div>{{msg.caption}}</div>
     
      <ul class="list-inline message-img  mb-0">
        <li class="list-inline-item message-img-list">
          <a class="d-inline-block m-1" :href="fileUrl">
            <img :src="fileUrl" alt="" class="rounded img-thumbnail" style="max-height: 100px">
          </a>                                                                  
        </li>                                           
      </ul>
    </div>
    <div v-if="msg.messageType === 'Document'" > 
      <div>{{msg.caption}}</div>
      <a :href="fileUrl" class="btn btn-info btn-rounded waves-effect waves-light btn-sm mt-1" >
        <i class="mdi mdi-file-download-outline "/>
        {{msg.fileName || 'file'}}
      </a>
    </div>
  </div>
</template>
