<script>
import Custom from "@/views/layouts/custom";
import appConfig from "@/app.config";
import { mapGetters,mapActions, mapMutations } from 'vuex';
import NoBots from './components/no-bots'
import List from './components/list'
import Conversation from './components/conversation';
import UserSelection from '@/components/user-selection';
import http from '@/app-http'
import Tags from '@/components/tags'
import Phones from '@/components/phones'
import Emails from '@/components/emails'
import { SimpleBar } from 'simplebar-vue3';

export default {
  page: {
    title: "Chat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Custom,
    NoBots,
    List,
    Conversation,
    UserSelection,
    Tags,
    Phones,
    Emails,
    SimpleBar
  },
  props: {
    contact:{
       type: String,
       required: false
    },
    id:{
       type: String,
       required: false
    }

  },
  data() {
    return {
      pagetitle: "Chat",
      initializing: true,
      linkToContactVisible: false,
      selectManagerVisible: false,
      selectedManager: null,
      linkToContact: this.createEmptyLinkToContact()
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('users', {
      users: 'itemsOnlyAlive'
    }),
    ...mapGetters('bots', {
      bots: 'bots',
      botsLoading: 'loading'
    }),
    ...mapGetters('chat', {
      ready: 'ready',
      listLoading: 'listLoading',
      selected: 'selected',
      isLinking: 'isLinking'
    }),
    isBusy() {
      return this.listLoading || !this.ready || this.botsLoading || this.initializing;
    }
  },
  watch:{
    contact () {
      this.init();
    },
    id() {
      this.init();
    },
  },  
  methods:{
    ...mapMutations('chat',{
      SET_QUERY_FILTER: 'SET_QUERY_FILTER'
    }),
    ...mapActions('bots', {
      loadBots: 'loadBots'
    }),
    ...mapActions('chat', {
      load: 'load',
      setManager: 'setManager',
      linkConversation: 'linkConversationToContact'
    }),
    async init() {
      this.initializing = true;
      await this.loadBots();
      this.SET_QUERY_FILTER({contactId: this.contact, conversationId: this.id});
      await this.load();
      this.initializing = false;
    },
    createEmptyLinkToContact(){
      return {
        keyword: '',
        name: '',
        phone: '',
        manager: [],
        contacts: [],
        loading: false,
        resultVisible: false
      };
    },
    showSelectManager() {
      this.selectManagerVisible = true;
      this.selectedManager = null;
    },
    hideSelectManager () {
      this.selectManagerVisible = false;
      this.selectedManager = null;
    },
    tryToSetManager (user) {
      this.hideSelectManager();
      this.setManager({platform: this.selected.platform, conversationId: this.selected.id, userId: user.id });  
    },
    showLinkToContact() {
      this.linkToContactVisible = true;
      this.hideSelectManager();
      this.linkToContact = this.createEmptyLinkToContact();
      this.linkToContact.name = this.selected.title,
      this.linkToContact.phone = this.selected.phoneNumber;
    },
    hideLinkToContact () {
      this.linkToContact = this.createEmptyLinkToContact();
      this.linkToContactVisible = false;
    },
    searchAgain() {
      this.linkToContact.resultVisible = false;
    },
    async searchContacts() {
      this.linkToContact.contacts = [];
      const req = {
        skip: 0,
        take: 50,
        keyword: this.linkToContact.keyword,
        manager: (this.linkToContact.manager || []).map(x => x.id),
        phoneNumber: this.linkToContact.phone,
        name: this.linkToContact.name
      };
      this.linkToContact.loading = true;
      const response = await http.post(`contacts-search`, req);
      this.linkToContact.contacts = response.data.items;
      this.linkToContact.loading = false;
      this.linkToContact.resultVisible = true;
    },
    async selectAndLinkContact(contact) {
      await this.linkConversation({ platform: this.selected.platform, conversationId: this.selected.id, contactId: contact.id } );
      this.hideLinkToContact();
    }
  }
};
</script>

<template>
  <custom :layout="'vertical'" :is-busy="isBusy" :title="pagetitle">
    <template #toptoolbar>

      
      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown-v"
          data-bs-toggle="dropdown"
          data-bs-reference="parent"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="mdi-cog-outline mdi font-size-24"></i>
          <i class="mdi mdi-chevron-down font-size-16"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end p-2">
          <router-link to="/bots" class="dropdown-item">
            <i class="mdi mdi-telegram me-1 "></i>Bots
          </router-link>
        </div>
      </div>

    </template>
    <template v-slot:content>
      <div class="container-fluid">
        <div v-if="!initializing && ready">
          <div v-if="!bots.hasBots">
            <no-bots></no-bots>
          </div>
          <div v-if="bots.hasBots">
            <div class="d-lg-flex">
              <div class="chat-leftsidebar card">
                
                <!-- SELECT RESPONSIBLE >>>> -->
                <div class="position-relative mb-4" v-if="selectManagerVisible">
                  <button type="button" aria-label="Close" class="btn-close" style="position: absolute; top:10px; right: 10px;" @click="hideSelectManager"></button>
                </div>
                <div v-if="selectManagerVisible" class="chat-message-list mt-4">
                  <ul class="list-group">
                    <li 
                      class="list-group-item"
                      style="border-radius: 0; border-left:0; border-right: 0"
                      v-for="user in users" :key="user.id" 
                    >
                      {{user.name}}
                       
                      <button 
                        class="btn btn-light btn-sm float-end"
                        @click="tryToSetManager(user)"
                      >
                          Select
                        </button>
                    </li>
                  </ul>
                </div>
                <!--SELECT RESPONSIBLE <<<< -->

                <!-- LINK TO CONTACT >>>> -->
                <div class="position-relative" v-if="linkToContactVisible">
                  <button type="button" aria-label="Close" class="btn-close" style="position: absolute; top:10px; right: 10px;" @click="hideLinkToContact"></button>
                  <div class="p-3 pt-0" v-if="!linkToContact.resultVisible">
                    <div class="mt-4">
                      <div class="form-group mb-3 mt-3">
                        <label>Name</label>
                        <input type="text" 
                          v-model="linkToContact.name"
                          class="form-control"     
                        />
                      </div>
                      <div class="form-group mb-3 mt-3">
                        <label>Phone Number</label>
                        <input type="text" 
                          v-model="linkToContact.phone"
                          class="form-control"     
                        />
                      </div>
                      <div class="form-group mb-3 mt-3">
                        <label>Additional keywords</label>
                        <input type="text" 
                          v-model="linkToContact.keyword"
                          class="form-control"     
                        />
                      </div>

                      <div class="mb-3  mt-3">
                        <label for="Manager-input" class="col-form-label">Manager</label>
                        <div>
                          <user-selection 
                            :value="linkToContact.manager" 
                            @update="v => linkToContact.manager = v"
                          >
                          </user-selection>
                        </div>
                      </div>

                      <div class="form-group mt-3 text-end">
                        <button class="btn btn-light " @click="searchContacts">
                          <i class=" fa fa-search me-1" v-if="!linkToContact.loading"/> 
                          <i class=" fa fa-spin fa-spinner me-1" v-if="linkToContact.loading"/> 
                          Search
                        </button>
                      </div> 
                    
                    </div>
                  </div>  
                  <div v-if="linkToContactVisible && linkToContact.resultVisible">
                    <div v-if="linkToContact.resultVisible && linkToContact.contacts.length === 0" class=" p-3">
                      <em>No constacts found...</em>
                    </div>
                    <div class="mt-4 mb-2 text-center p-3">
                      <button class="btn btn-light " @click="searchAgain">
                        Search Again
                      </button>
                    </div>
                     <SimpleBar data-simplebar  class="chat-message-list mt-4">
                      <ul class="list-group">
                        <li 
                          class="list-group-item"
                          style="border-radius: 0; border-left:0; border-right: 0"
                          v-for="contact in linkToContact.contacts" :key="contact.id" 
                        >
                        <div>
                          <router-link
                            :to="{ name: 'contact-details', params: { id: contact.id }}"
                          >
                            {{contact.name}}
                          </router-link>
                            
                          <span class="ms-2" v-if="contact.tags.length > 0">
                            <tags :value="contact.tags"></tags>
                          </span>
                        </div>
                        <div class="row" v-if="contact.phoneNumbers && contact.phoneNumbers.length > 0">
                          <div class="col-auto pe-1">
                            <i class="mdi mdi-phone"/>
                          </div>
                          <div class="col ps-0">
                            <span   class="ms-2 small">
                            <phones no-link  text :value="contact.phoneNumbers"></phones>
                          </span>
                          </div>
                        </div>
                        <div class="row" v-if="contact.emails && contact.emails.length > 0">
                          <div class="col-auto pe-1">
                            <i class="mdi mdi-email"/>
                          </div>
                          <div class="col ps-0">
                            <span   class="ms-2 small">
                              <emails no-link :value="contact.emails"></emails>
                            </span>
                          </div>
                        </div>
                          
                          <button 
                            class="btn btn-light btn-sm float-end"
                            v-if="contact.id !== (selected.contact || {}).id"
                            :disabled="isLinking"
                            @click="selectAndLinkContact(contact)"
                          >
                            <i class="fa fa-spin fa-spinner me-1" v-if="isLinking === contact.id"/>
                            Select
                          </button>
                        </li>
                      </ul>
                    </SimpleBar>
                  </div>
                 
                </div>
                <!-- LINK TO CONTACT <<<< -->

                <div class="p-3 pt-0 mt-3" v-if="!linkToContactVisible && !selectManagerVisible">
                  <div>
                    <select class="form-control mb-3">
                      <option selected>Show All Contacts</option>
                      <option>Show My Contacts</option>
                      <option>Show My And New Contacts</option>
                      <option>Show New Contacts</option>
                    </select>
                  </div>
                  <div class="search-box position-relative">
                    <input type="text" class="form-control rounded border" placeholder="Search...">
                    <i class="search-icon fa fa-search" style="width:26px; height:26px;line-height: 26px"></i>
                  </div>
                  <span class="float-end mt-1">
                    <a href="javascript:void(0)" class="small">
                      <i class="mdi mdi-database-search me-1"/> Advanced Search
                    </a>
                  </span>
                </div>

                <list v-if="!linkToContactVisible && !selectManagerVisible"></list>
              </div>
              <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-3">
                <conversation 
                  @link-to-contact="showLinkToContact" 
                  @select-manager="showSelectManager">

                </conversation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </custom>
  
</template>
