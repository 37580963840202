<script>
import { mapGetters, mapActions } from 'vuex';
import _ from "lodash";
import moment from 'moment-timezone';
import { SimpleBar } from 'simplebar-vue3';
import Message from './message';
import FileUpload from '@/components/file-upload';
import AudioRecord from '@/components/audio-record';
import Swal from 'sweetalert2'


const today     = moment().startOf('day').format("YYYY-MM-DD");
const yesterday = moment().startOf('day').subtract(1, 'days').format("YYYY-MM-DD");

export default {
  components: {
    SimpleBar,
    Message,
    FileUpload,
    AudioRecord
  },
  data() {
    return {
      text: null,
      forcedConversation: false,
      image: null,
      audio: null,
      video: null,
      doc: null,
      inputMode: 'text',
      recording: false,
      replyMsg: null,
      editedMsg: null,
      newContactFormVisible: false
    }
  },
  computed: {
    ...mapGetters('auth', {
        currentUser: 'currentUser'
    }),
    ...mapGetters('chat', {
      messages: 'messages',
      processing: 'messagesProcessing',
      loading: 'messagesLoading',
      chat: 'selected',
      isLinking: 'isLinking'
    }),
    isLinkContactButtonVisible() {
      if(!this.chat.isNewContact)
        return false;
      
      if(this.currentUser.isAdmin)
        return true;

      if(this.currentUser.id === (this.chat.manager || {}).id)
        return true;

      return false;
    },
    groupedMessages() {
      var map = _.groupBy(this.messages, function (msg) {
        const dt = msg.dateTime;
        return moment(dt).startOf('day').format("YYYY-MM-DD");
      });
      let keys = Object.keys(map);
      keys = keys.sort((a, b) => a.localeCompare(b));
      let res = [];
      for(let i = 0; i < keys.length; i++) {
        const key = keys[i];
        let subRes = {
         
        };
        if(today === key){
          subRes.title = "Today";
        } else if(yesterday === key){
          subRes.title = "Yesterday";
        } else {
          const msg =  map[key][0];
          const dt = msg.dateTime;
          subRes.title = moment(dt).startOf('day').format("L");
        }
        res.push(subRes);
        subRes["items"] = map[key].sort((a, b) => {
            const adt = a.dateTime;
            const bdt = b.dateTime;
            return moment(adt).toDate() < moment(bdt).toDate();
        });
      }
      return res;
    },
    newContactTemplate () {
      return {
        name: this.chat.title,
        phoneNumbers: this.chat.phoneNumber ? [this.chat.phoneNumber] : []
      }
    }
  },
  mounted (){
    this.scrollToDown();
  },
  watch: {
    messages(newValue, oldValue) {
      if((newValue || []).length !== (oldValue || []).length) {
        this.$nextTick(() => {
          this.scrollToDown();
        }); 
      }
    }
  },
  methods: {
    ...mapActions('chat', {
      makeMeManager: 'makeMeManager',
      sendMessage: 'sendMessage',
      markAsRead: 'markAsRead',
      markAllAsRead: 'markAllAsRead',
      deleteMessage: 'deleteMessage',
      linkConversation: 'linkConversationToContact'
    }),
    showSelectManager () {
      this.$emit('select-manager');
    },
    linkToContact() {
      this.$emit('link-to-contact');
    },
    reply(msg) {
      if(msg) {
        this.replyMsg = msg;
        if(this.$refs.msgText) {
          this.$refs.msgText.focus();
        }
        this.$nextTick(() => {
          this.scrollToDown();
        });
      }
    },
    cancelReply() {
      this.replyMsg = null;
    },
    edit(msg) {
      if(msg) {
        this.editedMsg = msg;
        if(this.$refs.msgText) {
          this.text = msg.text;
          this.$refs.msgText.focus();
        }

        if(msg.messageType === 'Text') {
          this.inputMode = 'text';
        } else {
          this.inputMode = 'attachment';
        }
        
        this.$nextTick(() => {
          this.scrollToDown();
        });
      }
    },
    cancelEdit() {
      if(this.editedMsg){
        this.editedMsg = null;
        this.inputMode = 'text';
      }
    },
    recordStarted() {
      this.recording = true;
    },
    recordStoped() {
      this.recording = false;
    },
    scrollToDown () {
      try {
        const simplebar = this.$refs.simplebarInstance;
        if(simplebar && simplebar.value && simplebar.value.el) {
          simplebar.value.recalculate();
          simplebar.value.el.getBoundingClientRect();
          const scrollHeight = simplebar.value.getScrollElement().scrollHeight;
          const scrollElement = simplebar.value.getScrollElement();
          scrollElement.scrollTo({ top: scrollHeight + 100 });
        }
      } catch(e){
        console.error(e);
      }
     
    },
    async sendFile(file, messageType){
      await this.sendMessage({ 
        id: (this.editedMsg || {}).id || null,
        fileBlob: file.fileBlob,
        mimeType: file.mimeType,
        fileName: file.fileName,
        messageType: messageType, 
        replyToId: (this.replyMsg || {}).id || null,
        isNotManagerSent: (this.chat.manager || {}).id != this.currentUser.id && this.forcedConversation 
      });
      this.cancelReply();
      this.cancelEdit();
      this.scrollToDown();
    },
    async sendImage() {
      if(this.image) {
        await this.sendFile(this.image,'Photo');
        this.image = null;        
      }
    },
    async sendAudio() {
      if(this.audio) {
        await this.sendFile(this.audio,'Audio');
        this.audio = null;
      }
    },
    async sendVideo() {
      if(this.video) {
        await this.sendFile(this.video,'Video');
        this.video = null;
      }
    },
     async sendDoc() {
      if(this.doc) {
        await this.sendFile(this.doc,'Document');
        this.doc = null;
      }
    },
    async sendVoice(voice) {
      await this.sendFile(voice,'Voice');
    },
    async sendTextMessage() {
      if(this.text) {
        await this.sendMessage({ 
          id: (this.editedMsg || {}).id || null,
          text: this.text, 
          messageType: 'Text', 
          replyToId: (this.replyMsg || {}).id || null,
          isNotManagerSent: (this.chat.manager || {}).id != this.currentUser.id && this.forcedConversation });
        this.text = '';
        this.cancelReply();
        this.cancelEdit();
        this.scrollToDown();
      }
     
    },
    tryMarkAllAsRead(msg) {
      if( (this.chat.manager && this.chat.manager.id === this.currentUser.id) || this.currentUser.isAdmin){
          this.markAllAsRead( { msgId: msg.id });
        }
    },
    tryMarkAsRead(msg) {
      if(!msg.readAt && msg.direction === 'Incoming') {
        if( (this.chat.manager && this.chat.manager.id === this.currentUser.id) || this.currentUser.isAdmin){
          this.markAsRead( { msgId: msg.id });
        }
      }
    },
    forceConversation () {
      this.forcedConversation = true;
    },
    canEdit(msg) {
      if(msg.messageType !== 'Text')
        return false;

      if((msg.from || {}).id === this.currentUser.id)
        return true;
      if((this.chat.manager || {}).id === this.currentUser.id)
        return true;
      if(this.currentUser.isAdmin)
        return true;
      
      return false;
    },
    canDelete(msg) {
      if((msg.from || {}).id === this.currentUser.id)
        return true;
      if((this.chat.manager || {}).id === this.currentUser.id)
        return true;
      if(this.currentUser.isAdmin)
        return true;
      
      return false;
    },
   
    tryToDelete(msgId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteMessage({id: msgId}).then(()=>{
            Swal.fire("Deleted!", "Message has been deleted.", "success");
          });
        }
      });
    },
    async uninkContact() {
      await this.linkConversation({ platform: this.chat.platform, conversationId: this.chat.id, contactId: null } );
    },
    onNewContact(){
      this.newContactFormVisible = true;
    },
    async onNewContactClose (id) {
      this.newContactFormVisible = false;
      if(id) {
        await this.linkConversation({ platform: this.chat.platform, conversationId: this.chat.id, contactId: id } );
      }
     
    }
  }
};
</script>

<template>
  <div class="card">
    <em class="p-4 text-muted" v-if="!chat">
      Please Select Chat.
    </em>

    <user-edit-form v-if="newContactFormVisible" @close="onNewContactClose" id="new" :template="newContactTemplate">
    </user-edit-form>

    
    <div v-if="chat"> 

      <div class="p-3 px-lg-4 border-bottom" >
        <div class="row">
          <div class="col-lg-6">
            <div class="d-flex align-items-center">
              
              <div class="flex-grow-1 mb-1 overflow-hidden">
                <h5 class="font-size-16 mb-1 text-truncate">
                  <i class="mdi mdi-telegram me-2" title="Telegram"  v-if="chat.platform === 'Telegram'"/>
                  <a href="javascript:void(0)" class="text-dark" > 
                    <router-link 
                      class="text-dark ms-2"
                      v-if="chat.contact"
                      :to="{ name: 'contact-details', params: { id: chat.contact.id }}"
                    >
                     <i class="mdi mdi-card-account-details-outline me-1"/>   {{chat.title}}
                    </router-link>
                    <span  v-if="!chat.contact">{{chat.title}}</span>
                    <i class="fa fa-spinner fa-spin" v-if="loading || isLinking === chat.id"/>
                    
                  </a>
                 
                </h5>
                <p class="mb-0">
                  <small class="me-2" v-if="chat.phoneNumber">
                     <i class="mdi mdi-cellphone me-1"/>{{chat.phoneNumber}}
                  </small>
                  <small  v-if="chat.hasManager"  class="me-2">
                    <i class="fa fa-user-tie me-2"/>Manager: {{chat.manager.name}}
                  </small>
                  <span  class="me-1 badge badge-soft-danger"   v-if="chat.isNewContact" >
                    <i class="mdi mdi-account-question me-2"/>no contact
                  </span>
                  <span  class="me-1 badge badge-soft-danger"   v-if="!chat.hasManager" >
                    no manager
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <ul class="list-inline user-chat-nav text-end mb-0">
              
             
              <li class="list-inline-item" v-if="!chat.contact">
                <button 
                  type="button" 
                  class="btn btn-light bg-gradient waves-effect btn-sm mb-1" 
                  v-if="isLinkContactButtonVisible"
                  @click="linkToContact"
                > 
                  <i class="mdi  mdi-account-search me-2"/>Link contact 
                </button>
              </li>
              <li class="list-inline-item" v-if="!chat.contact">
                <button 
                  type="button" 
                  class="btn btn-light bg-gradient waves-effect btn-sm mb-1" 
                  v-if="isLinkContactButtonVisible"
                  @click="onNewContact"
                > 
                  <i class="mdi mdi-plus me-2"/>New contact 
                </button>
              </li>
              <li class="list-inline-item">
                <div class="dropdown">
                  <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-horizontal-rounded"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="javascript:void(0)" v-if="chat.hasUnreadMessages" @click="tryMarkAllAsRead">
                      <i class="mdi mdi-check-all me-2"/>Mark all as read 
                    </a>  
                    <a class="dropdown-item" href="javascript:void(0)" v-if="(chat.manager || {}).id === currentUser.id || currentUser.isAdmin">
                      <i class="mdi mdi-account-off me-2"/>Block
                    </a>                    
                    <a class="dropdown-item" href="javascript:void(0)"  @click="onNewContact"  v-if="!chat.contact">
                      <i class="mdi mdi-account-plus-outline me-2"/>New contact
                    </a>  
                    <a class="dropdown-item" href="javascript:void(0)"   @click="linkToContact">
                      <i class="mdi mdi-account-search me-2"/>Link contact
                    </a>  
                    <a class="dropdown-item" href="javascript:void(0)" @click="uninkContact">
                      <i class="mdi mdi-account-minus-outline me-2"/>Unlink contact
                    </a> 
                    <a class="dropdown-item" href="javascript:void(0)" v-if="!chat.contact" @click="showSelectManager">
                      <i class="fa fa-user-tie me-2"/>Set manager
                    </a>   
                  </div>
                </div>
              </li>
            </ul>
                    
          </div>
        </div>
      </div>

      <SimpleBar class="chat-conversation" data-simplebar ref="simplebarInstance">
        <ul class="list-unstyled mb-0 mt-3 ms-3 me-3">
          <template v-for="(day, $index) in groupedMessages" :key="$index">
            <li class="chat-day-title"> 
              <span class="title">{{day.title}}</span>
            </li>
            <li 
              v-for="msg in day.items" :key="msg.id"
              :class="{'right text-right': msg.direction === 'Outgoing'}"
            >
             <div class="conversation-list">
                <div class="d-flex">
                  <div class="flex-1">
                    <div class="ctext-wrap mb-0" @click="tryMarkAsRead(msg)" :class="{'justify-content-end': msg.direction === 'Outgoing'}">
                      <div class="ctext-wrap-content" style="position: relative; min-width:100px">
                        <div>
                          <u v-if="msg.direction === 'Outgoing'">
                            <span class=" me-2 text-warning" v-if="msg.isNotManagerSent" title="Message sent by the not manager user">
                              <i class="mdi-account-alert mdi"/>
                            </span>
                            <span>
                               {{((msg.from || {}).name) || 'auto response'}}
                            </span>
                           
                           
                          </u>
                          <u v-if="msg.direction === 'Incoming'">
                            <span class="badge bg-danger rounded-pill" v-if="msg.notRead" style="position: absolute; left:-10px; top:-5px;cursor:pointer;">new</span>
                          </u>
                        </div>
                        <p class="mb-0"> 
                          <message :msg="msg" :conversation="chat"></message>
                        </p>
                      </div>
                      <div class="dropdown align-self-start">
                        <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </a>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="javascript:void(0)" @click="reply(msg)">Reply</a>
                          <a class="dropdown-item" 
                            href="javascript:void(0)" 
                            v-if="msg.direction === 'Outgoing' && canEdit(msg)" 
                            @click="edit(msg)"
                          >
                            Edit
                          </a>
                          <a class="dropdown-item" 
                            href="javascript:void(0)" 
                            v-if="msg.direction === 'Outgoing' && canDelete(msg)" 
                            @click="tryToDelete(msg.id)"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                    <small class="text-muted small overflow-hidden"> 
                      <div v-if="msg.failed" class="text-truncate">
                        <span class="text-danger" title="Failed">
                        <i class="mdi-message-alert-outline mdi me-2"/> {{msg.failedMessage}}
                        </span>
                      </div>
                      {{$filters.timeOnly(msg.dateTime)}}
                      <span v-if="msg.direction === 'Incoming' && msg.readBy">
                        | Read by: {{msg.readBy.name}}
                      </span>

                      <span v-if="!msg.isSent && !msg.failed" class="text-warning" title="Not sent">
                        <i class="mdi-clock-alert-outline mdi"/>
                      </span>
                    </small>
                    <div v-if="msg.editedAt">
                      <em v-if="msg.editedAt" class="ms-4">
                        <small class="text-muted small"> 
                          Edited at {{$filters.timeOnly(msg.editedAt)}}
                          <span v-if="msg.direction === 'Outgoing' && msg.editedBy">
                            by: {{msg.editedBy.name}}
                          </span>
                        </small>
                      </em>
                    </div>
                   
                  </div>
                </div>
              </div>
            </li>
          </template>
        </ul>
        <div class="ctext-wrap mb-0 bg-light p-3 text-left" v-if="replyMsg">
          <div class="ctext-wrap-content bg-light position-relative">
            <message :msg="replyMsg" :conversation="chat"></message>
            <button  type="button" class="btn-close position-absolute btn-sm" 
              @click="cancelReply"
              style="right: 0;top: 0;"
              data-bs-dismiss="alert" aria-label="Close">
            </button>
          </div>
        </div>
        <div class="ctext-wrap mb-0 bg-light p-3 text-left" v-if="editedMsg">
          <div class="ctext-wrap-content bg-light position-relative">
            <message :msg="editedMsg" :conversation="chat"></message>
            <button  type="button" class="btn-close position-absolute btn-sm" 
              @click="cancelEdit"
              style="right: 0;top: 0;"
              data-bs-dismiss="alert" aria-label="Close">
            </button>
          </div>
        </div>
      </SimpleBar>
      <div class="p-3 border-top" v-if="!chat.hasManager">
        <div class="row" >
          <div class="col">
            <div class="text-dark text-center">
              This is a new contact without manager. Make me manager and <a @click="makeMeManager" href="javascript:void(0)">
                 start a conversation</a>. <i class="fa fa-spin fa-spinner ms-1" v-if="processing"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="p-3 border-top" v-if="chat.hasManager ">
        <div class="row" v-if="chat.manager.id !== currentUser.id && !forcedConversation">
          <div class="col">
            <div class="text-dark text-center">
              You are not manager. Manager is <b>{{chat.manager.name}}</b>. Still, want to start converstation? 
              <a @click="forceConversation" href="javascript:void(0)">Yes, start a conversation</a> or <a href="javascript:void(0)" @click="makeMeManager">
                 make me manager</a>. <i class="fa fa-spin fa-spinner ms-1" v-if="processing"></i>
            </div>
          </div>
        </div>
        <div class="row" v-if="chat.manager.id === currentUser.id || forcedConversation">
          <div class="col-auto pe-0" v-if="!editedMsg">
              <button 
                href="javascript:void(0)" 
                class="btn bg-gradient waves-effect btn-light" 
                v-if="inputMode !== 'text'"
                @click="inputMode = 'text'"
              >
                <i class="mdi mdi-form-textbox"/>
              </button>
              <button 
                href="javascript:void(0)" 
                class="btn btn-light bg-gradient waves-effect" 
                v-if="inputMode !== 'attachment'"
                @click="inputMode = 'attachment'"
              >
                <i class="mdi mdi-attachment"/>
              </button>
          </div>

          <div class="col">
            <div  class="position-relative" style="height: 16px;" v-if="inputMode === 'attachment'">
              <audio-record @ready="sendVoice" @start="recordStarted" @stop="recordStoped" title="Record Voice" />
              <span v-if="!recording && !processing" class="ms-4">
                <file-upload v-model="image" is-image @ready="sendImage" title="Image" class="" lg/>
                <file-upload v-model="audio" is-audio @ready="sendAudio" title="Audio" class="ms-2" lg/>
                <file-upload v-model="video" is-video @ready="sendVideo" title="Video" class="ms-2" lg/>
                <file-upload v-model="doc" is-doc @ready="sendDoc" title="Document" class="ms-2" lg/>
             </span>
             <span class="ms-4" v-if="processing">
              <i class="fa fa-spinner fa-spin"></i>
             </span>
            </div>
            
            <div class="position-relative" v-if="inputMode === 'text'">
              <input 
                type="text" 
                ref="msgText"
                class="form-control border bg-soft-light" 
                v-model="text"
                placeholder="Enter Message..."
                @keyup.enter="sendTextMessage"
              />
            </div>
          </div>
          <div class="col-auto ps-0"  v-if="inputMode === 'text'">
            <button 
              type="button" 
              :disabled="!text || processing" 
              class="btn btn-primary chat-send w-md"
              @click="sendTextMessage"
              >
                <span class="d-none d-sm-inline-block me-2" >
                  Send
                </span> 
                <i class="mdi mdi-send float-end" v-if="!processing"></i>
                <i class="fa fa-spin fa-spinner float-end" v-if="processing"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
