<script>
import { mapGetters, mapActions } from 'vuex';
import TelegramBotEdit from "../telegram/bot-edit"
export default {
  components: {
    TelegramBotEdit
  },
  data() {
    return {
      editTelegramBotId: null
    };
  },
  computed: {
     ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('users', {
      loading: 'loading',
      users: 'itemsOnlyAlive'
    }),
    admins() {
      return (this.users || []).filter(x => x.isAdmin);
    }
  },
  created(){
    this.loadUsers();
  },
  methods: {
    ...mapActions('users', {
      loadUsers: 'loadUsers'
    }),
  }
};
</script>

<template>
  <div class="col">
    <div class="d-flex justify-content-center flex-nowrap">
      <telegram-bot-edit v-if="editTelegramBotId" @close="editTelegramBotId = null" :id="editTelegramBotId" />

      <div class="card" style="max-width:400px; min-width:300px;">
        <div class="card-body">
          <h6>You don't have any registered bots.</h6> 
          <div>
            <div class=" mt-4">
              To register a new bot, click on the button below.
            </div>
            <div class=" mt-4">
              <button 
                :disabled="!currentUser.isAdmin"
                class="btn btn-primary waves-effect waves-light"
                @click="editTelegramBotId = 'new'"
              >
                <i class="mdi mdi-telegram me-1"/>
                Register a Telegram Bot
              </button>
            </div>
          </div>
          <hr v-if="!currentUser.isAdmin"/>
          <div v-if="!currentUser.isAdmin">
            <div class=" mt-4">
              <i class="fa fas fa-exclamation-circle text-danger me-2"/> To register a bot, you must be an admin. Please contact your account administrator to register.
            </div>
            <div class="mt-4">
              Administrators:
            </div>
            <ul class="mt-1">
              <li v-for="user in admins" :key="user.id">
                {{user.name}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
