<script>
import { mapGetters, mapActions } from 'vuex';
import { SimpleBar } from 'simplebar-vue3';


export default {
  components: {
    SimpleBar
  },
  computed: {
    ...mapGetters('chat', {
      list: 'list',
      selectedId: 'selectedId',
      total: 'total',
      page: 'page',
      perPage: 'perPage'
    }),
    currentPage:
    {
      get: function() {
        return this.page || 1;
      },
      set: function(newValue) {
        this.setPage(newValue);
      }
    },
  },
  methods: {
    ...mapActions('chat', {
      select: 'select',
      setPage: 'setPage'
    }),
  }
};
</script>

<template>
  <div class="chat-leftsidebar-nav">
    
    <SimpleBar class="chat-message-list" data-simplebar>
      <em class="p-4" v-if="list.length === 0">No chats found...</em>
      <ul class="list-unstyled chat-list">
        <li 
          v-for="chat in list" :key="chat.id" 
          :class="{'active bg-light': selectedId === chat.id, 'unread': chat.hasUnreadMessages}"
        >
          <a 
            href="javascript:void(0)"
            @click="select({id:chat.id})"
          >
            <div class="d-flex align-items-start">
            
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="text-truncate font-size-13 mb-1">
                  <i class="mdi mdi-telegram me-1" title="Telegram"  v-if="chat.platform === 'Telegram'"/>
                  <i class="mdi mdi-card-account-details-outline me-1" v-if="chat.contact"/> {{chat.title}}
                </h5>
                <p class="text-truncate mb-0" v-if="chat.lastMessage">
                  <span>
                    {{chat.lastMessage.text || chat.lastMessage.caption || '...'}}
                  </span>
                </p>
                <p class="text-truncate mb-0 ">
                  <small  v-if="chat.hasManager"  class="me-2">
                    <i class="fa fa-user-tie me-2 text-muted"/>{{chat.manager.name}}
                  </small>
                  <span  class="me-1 badge badge-soft-danger"   v-if="chat.isNewContact" >
                    <i class="mdi mdi-account-question me-1"/>no contact
                  </span>
                  <span  class="me-1 badge badge-soft-danger"   v-if="!chat.hasManager" >
                      no manager
                  </span>
                </p>
              </div>
              <div class="flex-shrink-0">
                <div class="font-size-11">{{$filters.timeAgo(chat.lastActivityDateTime)}}</div>
              </div>
              <div class="unread-message" v-if="chat.hasUnreadMessages">
                <span class="badge bg-danger rounded-pill">{{chat.unreadMessageCount}}</span>
              </div>
            </div>
          </a>
        </li>                               
      </ul>
    </SimpleBar>
    
  </div>
</template>
